'use strict'

// import $ from 'jquery';
import _ from 'lodash';
import state from '../util/state.js';

const HeaderNav = class {
  constructor() {

    document.ontouchmove = function(e){ return true; }

    if(state.dev === 'pc') {
      this.drpNav();
    } else {
      this.tglNav();
    }

    window.addEventListener('resize', _.throttle(() => {
      if(state.dev === 'pc') {
        this.drpNav();
      } else {
        this.tglNav();
      }
    }, 500));
  }
  // for PC
  drpNav() {
    const drp = document.querySelector('.js-dnav'),
          dhd = drp.querySelector('.js-dnav-hd'),
          chl = drp.querySelector('.nav_sub'),
          dbg = document.querySelector('.nav_bg');

    dhd.addEventListener('mouseenter', e => {
      _.forEach({ drp, chl, dbg }, function(n, key) {
        n.classList.add('current');
      });
    });
    drp.addEventListener('mouseleave', e => {
      _.forEach({ drp, chl, dbg }, function(n, key) {
        n.classList.remove('current');
      });
    });
  }
  // for SP
  tglNav() {
    const tgl = document.querySelector('.js-tgl-btn');
    const nav = document.querySelector('header.global .nav_tgl');
    const bd = document.querySelector('body');

    tgl.onclick = function(e) {
      e.preventDefault();
      const btn = this;

      if(!state.tglOpen) {
        bd.classList.add('fixed');
        document.ontouchmove = function(e){ e.preventDefault(); }
        state.fixed = true;

        _.forEach({ btn, nav }, function(n, key) {
          n.classList.add('current');
        });
      } else {
        bd.classList.remove('fixed');
        document.ontouchmove = function(e){ return true; }
        state.fixed = false;

        _.forEach({ btn, nav }, function(n, key) {
          n.classList.remove('current');
        });
      }
      state.tglOpen = !state.tglOpen;
    }
  }
};
export default HeaderNav;
