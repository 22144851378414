'use strict'

const Footer = class {
  constructor() {
    this.tgt = {
			fnav: document.querySelector('.js-fnav'),
      snav: document.querySelector('.js-fnav-sub'),
    }
    this.init();
  }
  init() {
    this.tgt.fnav.addEventListener('mouseenter', e => {
      this.tgt.snav.classList.add('current');
    });
    this.tgt.fnav.addEventListener('mouseleave', e => {
      this.tgt.snav.classList.remove('current');
    });
  }

};
export default Footer;
