'use strict'

import $ from 'jquery';
import { InViewPort } from 'in-view-js';

const Inview = class {
  constructor(el) {
    const tgt = $(el);

    tgt.each(function(){
      let element = this,
        callback = function(isInView){

          if(isInView){
            $(element).addClass('inview');
          }else{
            // $(element).removeClass('inview');
          }
        };
      let inViewInstance = new InViewPort({
        element,
        callback
      });

      inViewInstance.addListeners();
    });
    // 
    window.scroll({ top: 1 });
    window.scroll({ top: 0 });
  }
}

export default Inview;
