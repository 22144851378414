'use strict'

import $ from 'jquery';

const Tglbox = class {
  constructor(el) {

    $('.js-tgl-bd').hide();
    $('.current .js-tgl-bd').show();

    const tgt = $(el);
    tgt.each(function(){
      const tgl = this,
            hd = $(tgl).find('.js-tgl-hd'),
            bd = $(tgl).find('.js-tgl-bd'),
            btn = $(tgl).find('.js-tgl-btn');

      $(btn).on('click', function(e){
        $(bd).slideToggle();
        $(this).toggleClass('current');
      });
      // $(cls).on('click', function(e){
      //   $(bd).slideUp();
      //   $(tgl).removeClass('current');
      // });
    });
  }
};
export default Tglbox;
