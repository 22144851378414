'use strict'

/* =================================================
pageState
==================================================== */

const state = {
	init: false,
	dev: 'pc',
	cls: '',
	fixed: false,
	land: false,
	lospec: false,
	stage: false,
	tglOpen: false
};

export default state;
