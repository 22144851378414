'use strict'

import $ from 'jquery';
import Barba from 'barba.js'
import Tglbox from '../modules/Tglbox';
import Inview from '../modules/Inview';
import Intro from '../modules/Intro';
import state from '../util/state.js';

const Pjax = class {
  constructor() {

    // No reload if current page move (option)
    var links = document.querySelectorAll('a[href]');
    var cbk = function(e) {
     if(e.currentTarget.href === window.location.href) {
       e.preventDefault();
       e.stopPropagation();
     }
    };
    for(var i = 0; i < links.length; i++) {
      links[i].addEventListener('click', cbk);
    }

    // Dispatcher Ready
    Barba.Dispatcher.on('newPageReady', function(currentStatus, oldStatus, barbaContainer, newPageRawHTML) {

      // Send to Analytics(Google Analytics)
      if (typeof ga === 'function') {
         ga('send', 'pageview', location.pathname);
      }

      // Do inline javascripts
      var temp = document.createElement('div');
      temp.innerHTML = newPageRawHTML;
      var hoge = temp.querySelector('.hoge script');
      if(hoge != null){
        eval(hoge.innerHTML);
      }

      // Change meta tags
      var $newPageHead = $( '<head />' ).html(
         $.parseHTML(
            newPageRawHTML.match( /<head[^>]*>([\s\S.]*)<\/head>/i )[ 0 ],
            document,
            true
         )
      );
      //
      var headTags = [
         // "link[rel='canonical']",
         "meta[name='description']",
         "meta[property^='og']",
         "meta[name^='twitter']",
         "meta[name='robots']"
      ].join( ',' );
      $('head').find(headTags).remove();
      $newPageHead.find(headTags).appendTo('head');


      // Close Header nav
      if(state.dev === 'pc') {
        const drp = document.querySelector('.js-dnav'),
              dhd = document.querySelector('.js-dnav-hd'),
              chl = drp.querySelector('.nav_sub'),
              dbg = document.querySelector('.nav_bg');

        _.forEach({ drp, chl, dbg }, function(n, key) {
          n.classList.remove('current');
        });
        // chl.classList.remove('current');
        // dbg.classList.remove('current');
        // setTimeout(() => {
        //   dbg.style.display = 'none';
        // }, 600);
      } else {
        const tgl = document.querySelector('.js-tgl-btn'),
              nav = document.querySelector('header.global .nav_tgl'),
              bd = document.querySelector('body');
        bd.classList.remove('fixed');
        document.ontouchmove = function(e){ return true; }
        state.fixed = false;
        state.tglOpen = false;

        _.forEach({ tgl, nav }, function(n, key) {
          n.classList.remove('current');
        });
      }

      // Other script
      new Inview('.js-in');
      if(document.querySelector('.js-tgl')) {
        new Tglbox('.js-tgl');
      }
      if(document.querySelector('.js-intro')) {
        new Intro();
      }

      // // Replace body class
      // var $body = $('body');
      // state.cls = currentStatus.namespace;
      //
      // if(currentStatus && currentStatus.namespace) {
      //   $body.addClass(currentStatus.namespace);
      // }
      //
      // if(oldStatus && oldStatus.namespace) {
      //   if(!currentStatus
      //   || (currentStatus.namespace && currentStatus.namespace !== oldStatus.namespace)) {
      //     setTimeout(() => {
      //       $body.removeClass(oldStatus.namespace);
      //     }, 800);
      //   }
      // }

    }); // End Dispatcher Ready


    // Dispatcher Complete
    Barba.Dispatcher.on( 'transitionCompleted', function(currentStatus, prevStatus) {

      const body = document.querySelector('body');
      console.log(currentStatus.url);

      if(currentStatus.url.indexOf('philosophy') > 0 || currentStatus.url.indexOf('shoplist') > 0 || currentStatus.url.indexOf('allforwoman') > 0) {
        body.classList.add('popup');
      } else {
        body.classList.remove('popup');
      }
    });


    // Animation
    var fadeTransition = Barba.BaseTransition.extend({
      start: function() {
        Promise
          .all([this.newContainerLoading, this.fadeOut()])
          .then(this.fadeIn.bind(this));
      }, // End start function

      fadeOut: function() {
        // for Old page
        return $(this.oldContainer).animate({ opacity: 0 },{duration: 400}).promise();
        // $(this.oldContainer).css('position', 'absolute');
        // return $(this.oldContainer).animate({ left: '-100%', opacity: 0 },{duration:'fast'}).promise();
      },

      fadeIn: function() {
         // to Top scroll
         document.body.scrollTop = 0;

         var _this = this;
         var $el = $(this.newContainer);

         // Hide Old page
         $(this.oldContainer).hide();

         // Set New page
         $el.css({
            visibility: 'visible',
            opacity: 0,
         });

         $el.animate({opacity: 1}, 400, function() {

            $el.css('position', 'relative');

            // for Inview js
            window.scroll({ top: 1 });
            window.scroll({ top: 0 });

            _this.done();
         });
      }
    }); // End BaseTransition

    // Set transition
    Barba.Pjax.getTransition = function() {
      return fadeTransition;
    };

    Barba.Pjax.start();
    Barba.Prefetch.init(); // enable prefetch

  }
}

export default Pjax;
