'use strict'

import _ from 'lodash';
import state from '../util/state';

const Intro = class {
  constructor() {
    if(!state.init) {
      this.intro();
    } else {
      this.short();
    }
  }
  intro() {
    document.querySelector('.main').classList.add('intro');

    state.init = true;
    const hd = document.querySelector('header.global'),
          cn = document.querySelector('.contents'),
          ft = document.querySelector('footer.global'),
          mn = document.querySelector('.main'),
          wp = document.querySelector('#wrap');

    this.intro01 = () => {

      _.forEach({ hd, ft }, (n, key) => {
        n.classList.add('ready');
      });
      mn.classList.add('intro');
      _.forEach(document.querySelectorAll('.main .slideup_item'), function(n, key) {
        n.style.opacity = 1;
      });

      setTimeout(() => {
        cn.classList.add('ready');
        this.intro02();
      }, 1200);
    };

    this.intro02 = () => {
      mn.classList.add('ready');
    };

    this.intro01();
  }
  short() {
    const hd = document.querySelector('header.global'),
          cn = document.querySelector('.contents'),
          ft = document.querySelector('footer.global'),
          mn = document.querySelector('.main'),
          wp = document.querySelector('#wrap');

    setTimeout(() => {
      _.forEach({ hd, cn, ft }, (n, key) => {
        n.classList.add('ready');
      });
      mn.classList.add('short');
    }, 1000);
  }
}

export default Intro;
