import './polyfill'
// import $ from 'jquery';
import Header from './modules/Header';
import HeaderNav from './modules/HeaderNav';
import Footer from './modules/Footer';
import Link from './modules/Link';
import Pagetop from './modules/Pagetop';
import Pjax from './modules/Pjax';
import Tglbox from './modules/Tglbox';
import Inview from './modules/Inview';
import Intro from './modules/Intro';
import state from './util/state';

state.dev = (window.innerWidth > 768) ? 'pc' : 'sp';

window.addEventListener('DOMContentLoaded', function() {
  new HeaderNav();
  new Link('.slink');
  // new Pagetop();
  new Pjax();

  if(state.dev == 'pc') {
    new Header();
    new Footer();
  } else {
    const nav = document.querySelector('.nav.spShow');
    console.log(nav);
    document.querySelector('.nav.spShow').style.height = window.innerHeight + 'px';
  }

});

window.onload = function(){

  if(document.querySelector('.js-intro')) {
    // new Intro();
  } else {
    state.init = true;
    const hd = document.querySelector('header.global'),
          cn = document.querySelector('.contents'),
          ft = document.querySelector('footer.global'),
          wp = document.querySelector('#wrap');
    //
    wp.classList.add('s2');
    _.forEach({ hd, cn, ft }, (n, key) => {
      n.classList.add('ready');
    });

    // setTimeout(() => {
    //   wp.classList.add('s2');
    //   setTimeout(() => {
    //     _.forEach({ hd, cn, ft }, (n, key) => {
    //       n.classList.add('ready');
    //     });
    //   }, 800);
    // }, 1000);
  }
};

window.addEventListener('resize', _.throttle(function() {
  state.dev = (window.innerWidth > 768) ? 'pc' : 'sp';
}, 500));
