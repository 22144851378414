'use strict'

import _ from 'lodash';
import state from '../util/state';

const Header = class {
  constructor() {
    const hd = document.querySelector('header.global');
    this.tgt = {
			hd: document.querySelector('header.global'),
      hdH: hd.offsetHeight,
      pos: 0
    }
    window.addEventListener('scroll', _.throttle(this.scrl.bind(this), 500));
    this.scrl();
  }
  scrl() {
    const val = window.pageYOffset;
    if (val > 120) {
      // this.tgt.hd.style.top = '-' + this.tgt.hdH + 'px';
      this.tgt.hd.classList.add('min');
    } else {
      // this.tgt.hd.style.top = 0;
      this.tgt.hd.classList.remove('min');
    }
    this.tgt.pos = val;
  }

};
export default Header;
